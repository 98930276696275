.CasesByDateMap {
  color: white;
  line-height: 1.5em;
  width: 100%;
  max-width: 900px;
  margin: 0px auto;
  
  .header {
    padding-top: 20px;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 1em;
      margin-bottom: 20px;
    }
    .subtitle {
      font-size: 1.5em;
    }
  }

  .player {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .playButton {
      width: 25px;
      height: 25px;
      margin-right: 5px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor:pointer;
    }

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 15px;
      background: #d3d3d3;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
      margin-bottom: 0;
    
      &:hover {
        opacity: 1;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        background: #444;
        cursor: pointer;
      }
    }
  }

  .map {
    margin: 0px auto;
  }

  .table-item {
    .label {
      width: 250px;
    }
    .label, .value {
      display: inline-block;
    }
  }

  .footer {
    font-family: monospace;
    display: flex;
    margin-bottom: 10px;
    > div:first-child {
      flex-grow: 1;
    }
  }

  .legend-item {
    display: flex;
    align-items: center;
  }

  .swatch {
    width: 1em;
    height: 1em;
    display: inline-block;
    border: 1px solid #555;
    margin-right: 10px;

    &.red { background-color: rgb(125, 44, 44); }
    &.black { background-color: #111; }
  }
}