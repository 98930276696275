$content-background-color: #202128;
$tab-background-color: #303138;
$highlight-color: #404350;
$hover-color: #34353d;

$first-column-width: 65px;
$column-width: 87px;


.DataTable {
  flex-grow: 1;
  overflow: hidden;
  border-top: .5px solid grey;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1.6em;

  .graph-tooltip {
    background: black;
    padding: 5px;
  }

  .DataTable-tooltip {
    max-width: 250px;
  }
  
  .header-row {
    background-color: $content-background-color;
    font-weight: bold;
    border-bottom: .5px solid gray;
    padding: 3px 0;
    padding-right: 17px;
    line-height: 1.2em;
    user-select: none;
    position: relative;

    .data-sub-row {
      cursor:pointer;
      align-items: center;
      position: relative;
      display: inline-flex;
      min-width: 100%;
    }

    .header-cell {
      display: flex;
      align-items: center;
      font-size: 14px;
      
      &:first-child {
        flex-grow:1;
        width: $first-column-width;
        min-width: $first-column-width;
        padding-left: 5px;
      }
      &:not(:first-child) {
        width: $column-width;
        text-align: right;
        min-width: $column-width;
        margin-right: 5px;
  
        &.sera {
          max-width: 40px;
          min-width: unset;
        }

        > span:first-child {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .rows {
    overflow-x: auto;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .data-row {
    min-width: 100%;
    display: inline-flex;
    flex-direction: column;

    &:nth-child(odd) .data-sub-row {
      background: #1b1c22;
    }
    &:nth-child(even) .data-sub-row {
      background: #202128;
    }
    &:last-child .data-sub-row {
      border-bottom: .5px solid grey;
    }
    &:hover .data-sub-row {
      background: $hover-color;
    }

    &.expanded {
      .data-sub-row {
        background-color: $highlight-color;
        
        .name {
          font-weight: bold;
        }
      }
    }

    /*
    &:hover:not(.selected) {
      background-color: #364056;
    }
    &.selected {
      background-color: #2e3649;
    }
    */

    .data-sub-row {
      display: flex;
      cursor:pointer;
      align-items: center;
      position: relative;

      >:first-child {
        width: $first-column-width;
        min-width: $first-column-width;
        flex-grow:1;
        padding-left: 5px;
      }

      >:not(:first-child) {
        width: $column-width;
        text-align: right;
        min-width: $column-width;
        margin-right: 5px;

        &.sera {
          display: flex;
          padding-left: 5px;
          max-width: 40px;
          min-width: unset;

          .sera-button {
            color: white;
            font-size: .75em;
            padding: 0px 2px;
            border: 1px solid gray;
            background: $content-background-color;
            cursor: pointer;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .chart-sub-row {
      font-size: .8em;
      overflow-y: hiddden;

      > .chart:first-child {
        margin-top: 20px;
      }

      .chart {
        width: 100%;
      }

      .chart-title {
        height: 15px;
        text-align: left;
        padding-left: 90px;
      }
    }
  }
}