$content-background-color: #202128;
$tab-background-color: #303138;

input.countyInput {
  background: $content-background-color;
  color: gray;
  outline: none;
  border: .5px solid gray;
  padding: 4px;
  width: 100%;
  margin-top: -2px;
}

.mobility { color: #83A7ec; }
.health { color: #C94CA8; }
.economy { color: #3DC986; }
.vulnerable-population { color: #df9530; }

.CovidDashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .loading-overlay {
    position: fixed;
    z-index: 99999999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    // background-color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 2em;
      user-select: none;
      margin: 0;
      position: relative;
      top: -70px;

      &+p {
        font-size: 1em;
      }
    }
  }

  .container {
    background: $content-background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &.mobile {
      overflow: auto;
    }
  }

  .tab-navigator {
    width: 100%;
    background: $tab-background-color;
    border: 1px solid $tab-background-color;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    .tabs {
      display: flex;
      min-height: 40px; // For Safari...
      
      .button {
        cursor: pointer;
        padding: 5px 20px;
        text-align: center;
        width: 100%;
        font-size: 1.5em;
        &.selected {
          background: $tab-background-color;
          font-weight: bold;
        }
        &:not(.selected) {
          background: #181818;
        }
      }
    }

    .tab-navigator-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      .controls {
        padding: 5px;
      
        .row {
          display: flex;
          margin-bottom: 5px;
          align-items: center;
          justify-content: center;

          > :not(:last-child) {
            margin-right: 5px;
          }

          .date-range-selection {
            text-align: center;
            width: 100%;
            > :not(:last-child) {
              margin-right: 5px;
            }

            .datePicker {
              display: inline-block;
              

              .covid-date-picker {
                color: gray;
                width: 130px;
                background-color: $content-background-color;
                border: .5px solid gray;
                padding: 5px;
                text-align: center;
              }
            }
          }

          .inline-column-chooser {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .column-chooser-item {
              padding: 2px 5px;
              margin-bottom: 5px;
              margin-right: 5px;
              border-radius: 5px; 
              cursor: pointer;

              &:not(.selected) {
                padding: 3px 6px;
              }
            }
          }
        }
      }
    }

    .date-buttons {
      margin-left: 10px;
      flex-grow: 1;
      display: flex;
      .date-button {
        text-decoration: underline;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
        &.selected {
          background: rgb(48, 151, 48);
        }
      }
    }

    .column-choosers {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      >:not(:last-child) {
        margin-right: 10px;
      }

      .select-metrics-label {
        line-height: 1.1;
        text-align: right;
      }

      .column-chooser {
        position: relative;
        background: $content-background-color;
        
        button {
          background-color: $tab-background-color;
          height: 100%;
          border: 1px solid gray;
          cursor: pointer;
          user-select: none;
          align-items: center;
          outline: none;
          padding: 5px;
          font-size: 1.1em;

          .category {
            display: flex;
            justify-content: center;

            .name {

              > span {
                white-space: nowrap;
              }
            }

            .arrow {
              align-self: center;
              position: relative;
              top: 1px;
              font-size: .8em;
            }
          }
        }

        .column-checkboxes {
          position: absolute;
          top: calc(100% - 1px);
          left: 0;
          background-color: $content-background-color;
          padding: 10px;
          border: 1px solid gray;
          width: 300px;
          z-index: 100;
        }
      }
    }
    #economy-column-chooser .column-checkboxes {
      left: unset;
      right: 0;
    }
    #vulnerablePopulation-column-chooser .column-checkboxes {
      left: unset;
      right: 0;
    }
  }


  .list-and-map {
    background: $content-background-color;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;

    .list-container {
      width: 45%;
      min-width: 550px;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      border-top: .5px solid $tab-background-color;
      border-bottom: .5px solid $tab-background-color;
      border-right: .5px solid $tab-background-color;
      flex-grow: 1;
    }

    .CovidMap {
      width: 55%;
      min-width: 850px;
      flex-grow: 1;
    }
  }
  .CasesByDateMap {
    padding: 10px;
    overflow: hidden;

    .title {
      display: none;
    }
  }

  .panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .map-panel {
    height: 100%;
  }

  .__react_component_tooltip.show {
    opacity: 1;
    border: 1px solid white;

    // turn off the arrow
    &::before, &::after {
      display: none;
    }
  }

  .custom-tooltip {
    background-color: rgba(9,9,9,.6);
    font-size: 14px;
    padding: 3px;
  }
}
