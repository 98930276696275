$content-background-color: #202128;
$tab-background-color: #303138;
/*
C42A59
#FEDD87
#85D1A5
*/
$feedback-pass-color: #85D1A5;
$feedback-fail-color: #FF4848;
$feedback-warn-color: #FEDD87;

.SERA {
  height: 100%;
  position: relative;
  border-top: 1px solid $tab-background-color;
  border-left: 1px solid $tab-background-color;
  overflow: auto;

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  header {
    padding: 2px 5px;
    background: $tab-background-color;
    margin: 0;
    line-height: 1.1sem;
    display: flex;

    .title {
      font-size: 1.25em;
      flex-grow: 1;
      color: white;
    }
    .close-button {
      cursor: pointer;
    }
  }

  section.body {
    overflow-y: auto;
    flex-grow: 1;
    max-height: 100%;
    padding-top: 10px;
  }

  .feedback-key {
    display: flex;
    margin-top: 2px;
    position: relative;

    > div {
      margin-left: 23px;
      
      &:first-child {
        margin-left: 12px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        margin-left: -12px;
        margin-top: 4px;
      }

      &.pass::before {
        background-color: $feedback-pass-color;
      }

      &.fail::before {
        background-color: $feedback-fail-color;
      }

      &.warn::before {
        background-color: $feedback-warn-color;
      }
    }
  }

  .info {
    padding: 0px 10px 10px 10px;
    
    .region-name {
      font-size: 1.2em;
    }
  }

  .category {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .category-title {
      font-weight: bold;
      font-size: 1.2em;
      text-decoration: underline;
      margin-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .field-wrapper {
      &:nth-child(even) {
        background: #1b1c22;
      }
      &:nth-child(odd) {
        background: #202128;
      }

      .field {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        position: relative;

        .feedback-pass {
          color: $feedback-pass-color;
        }
        .feedback-fail {
          color: $feedback-fail-color;
        }
        .feedback-warn {
          color: $feedback-warn-color;
        }

        .field-feedback {
          width: 5px;

          > div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 6px;
            justify-content: center;
            padding-top: 10px;
          }

          div.feedback-fail {
            background-color: $feedback-fail-color;
          }

          div.feedback-pass {
            background-color: $feedback-pass-color;
          }

          div.feedback-warn {
            background-color: $feedback-warn-color;
          }
        }

        .field-info {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding-bottom: 10px;

          .field-title {
            font-weight: 700;
            padding: 10px 0 0 5px;
          }

          .field-entries {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;

            > :not(:last-child) {
              margin-right: 10px;
            }

            .entry {
              display: flex;
              align-items: center;
              flex-direction: column;
              line-height: 1em;
              width: 100%;
              text-align: center;
              
              &.field-value {
                .value {
                  margin-top: 10px;
                  font-size: 1.25em;
                }
              }

              &.field-percentile {
                .rank-top {
                  background-image: url('../assets/thumbsup.svg');
                  width: 15px;
                  height: 15px;
                }
                .rank-bottom {
                  background-image: url('../assets/thumbsdown.svg');
                  width: 15px;
                  height: 15px;
                }
              }

              .label {
                font-size: .65em;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }

  button {
    margin-top: 10px;
    font-size: 16px;
    width: 100px;
    background-color: #29aae1;
    cursor: pointer;
    border: unset;
    color: white;
    padding: 10px 0px;
    float: right;

    &:hover {
      background-color: #85c8e0;
    }
  }
}