@import 'common.scss';

$content-background-color: #202128;
$tab-background-color: #303138;

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background: $content-background-color;
  font-size: 14px;
  color: #ccc;
  font-family: 'Baloo 2', sans-serif;

  .footer {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
}