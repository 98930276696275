@import 'common.scss';

.CovidArticle {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  color: white;

  .container {
    background: #202128;
    padding-bottom: 1em;

    article {
      width: 100%;
      max-width: 900px;
      margin: 0px auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}