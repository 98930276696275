$content-background-color: rgba(32, 33, 40, .9);
$content-background-color-transparent: rgba(32, 33, 40, 75);
$tab-background-color: #303138;

.CovidMap {
  color: #ccc;
  line-height: 1.5em;
  position:relative;
  display: flex;
  flex-direction: column;

  select {
    background: $content-background-color;
    color: #ccc;
    border: .5px solid gray;
    outline: none;
    height: 2em;
    option {
      background-color: $tab-background-color;
    }
  }
  
  .controls {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 0px;
    display: flex;
    white-space: nowrap;

    .zoom-controls {
      font-size: 1.25em;
    }
    .measures-selection {
      font-size: 1.25em;
    }

    > :not(last-child) {
      margin-right: 10px;
    }
  }

  .sidebar {
    position: absolute;
    top: 1px;
    right: 0px;
    width: 300px;
    bottom: 0px;

    .national-stats-button {
      margin-top: 5px;
      margin-right: 10px;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 1.25em;
      font-weight: bold;
      float: right;
      border: .25px solid gray;
    }
  }

  .selected-date-label {
    position: absolute;
    top: 40px;
    font-size: 1.25em;
    //left: 10px;
  }

  .chart-overlay {
    border: .5px solid gray; 
    background: $content-background-color;
    padding: 5px;
    width: 300px;
    position: absolute;
    top: 75px;
    left: 10px;
    z-index: 1;

    .header {
      display: flex;
      
      .region-name {
        font-weight: bold;
        flex-grow: 1;
      }
      .close-button {
        cursor: pointer;
        user-select: none;
        position: relative;
        top: -5px;
      }
    }

    .chart-title {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .player-container {
    position: absolute;
    bottom: 0px;
    padding: 10px;

    .legend {
      width: 500px;
      margin-bottom: 5px;
      display: flex;

      .legend-item {
        width: 100%;
        border-top: .5px solid gray;
        border-bottom: .5px solid gray;
        border-left: .5px solid gray;
        padding: 2px 5px;

        &:not(:first-child) {
          border-right: .5px solid gray;
        }
      }
    }

    .date-labels {
      display: flex;
      
      >:first-child {
        flex-grow: 1;
      }
    }
    .player {
      display: flex;
      align-items: center;
      justify-content: center;
      
      .playButton {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.9;
        -webkit-transition: .2s;
        transition: opacity .2s;
        margin-bottom: 0;
      
        &:hover {
          opacity: 1;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 14px;
          height: 14px;
          background: #444;
          cursor: pointer;
        }
      }
    }
  }
  

  .map {
    margin: 0px auto;
    flex-grow: 1;
    position: absolute;
    top: 75px;
    left: 10px;

    .loading-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 18px;
      }
    }

    &.isLoading svg {
      opacity: .3;
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;

    
      path {
        outline: none;
      }
    }
  }

  /*
  .table-item {
    .label {
      flex-grow: 1;
      width: 100%;
    }
    .label, .value {
      display: inline-block;
    }
  }
  */

  .footer {
    font-family: monospace;
    display: flex;
    margin-bottom: 10px;
    > div:first-child {
      flex-grow: 1;
    }
  }

  .legend-item {
    display: flex;
    align-items: center;
  }

  .swatch {
    width: 1em;
    height: 1em;
    display: inline-block;
    border: 1px solid #555;
    margin-right: 10px;

    &.red { background-color: rgb(125, 44, 44); }
    &.black { background-color: #111; }
  }
}