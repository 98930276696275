.Credits {
  background: black;
  color: white;
  padding: 20px;
  display: flex;

  .entry {
    width: 100%;
    text-align: center;

    .logo {
      height: 40px;
      margin-top: .5em;
    }
  }
}