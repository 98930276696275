$content-background-color: #202128;
$tab-background-color: #303138;

.NationalStats {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-top: 1px solid $tab-background-color;
  border-left: 1px solid $tab-background-color;
  
  .header {
    background: $tab-background-color;
    //border-left: 1px solid $tab-background-color;
    display: flex;
    height: 2em;
    padding: 2px 0px 2px 5px;

    .title {
      font-size: 1.25em;
      flex-grow: 1;
    }
    .close-button {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0px;
    top: 2em;
    overflow: auto;

    .table-item {
      display: flex;
      align-items: center;
      line-height: 1.1em;
      padding: 5px;
      //border-left: 1px solid $tab-background-color;
      cursor: pointer;
      
      &:nth-child(odd) {
        background: #1b1c22;
      }
      &:nth-child(even) {
        background: #202128;
      }
      &:last-child {
        //border-bottom: 1px solid $tab-background-color;
      }

      .label {
        flex-grow: 1;
      }
      .value {
        text-align: right;
      }
    }
  }
}