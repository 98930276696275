.AnnouncementModal {
	position: fixed;
	z-index: 999999999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(black, .6);
	display: flex;
	justify-content: center;
	align-items: center;

	article {
		background-color: #202128;
		padding: 20px;
		color: #ccc;
		max-height: 650px;
		max-width: 700px;
		width: calc(100vw - 10px);
		// height: calc(100vh - 10px);
		display: flex;
		flex-direction: column;

		h1 {
			margin-top: 0;
		}

		> div {
			overflow-y: auto;
			flex-grow: 1;

			> img {
				width: 100%;
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: 10px;
			}

			> p {
				margin: 0;
			}
		}

		button {
			margin-top: 10px;
			font-size: 16px;
			width: 100px;
			background-color: #29aae1;
			cursor: pointer;
			border: unset;
			color: white;
			padding: 10px 0px;
			float: right;

			&:hover {
				background-color: #85c8e0;
			}
		}
	}
}