/* For easy addition to plain html files' style tags, this files is plain CSS */
@font-face {
  font-family: BemboStd;
  src: url("../assets/BemboStd.otf") format("opentype"); /*This path need to be updated when copied to the plain html files*/
}
@font-face {
  font-family: Intestate;
  src: url("../assets/Interstate-regular.ttf"); /*This path need to be updated when copied to the plain html files*/
}

.MTIHeader {
  background: black;
  color: white;
  font-size: 14px;
}

.MTIHeader .main-header {
  min-height: 70px; /* allow height to scale up to support mobile portrait */
  padding: 0px 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.MTIHeader .main-header .app-title {
  flex-grow: 1;
}
.MTIHeader .main-header .app-title .umd {
  font-family: "BemboStd", serif;
  text-transform: uppercase;
}
.MTIHeader .main-header .app-title .title {
  font-family: Interstate, sans-serif;
  font-weight: bold;
  font-size: 2em;
}

.MTIHeader .team-logos {
  display: flex;
  align-items: center;
  height: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.MTIHeader .team-logos .entry {
  height: 100%;
}
.MTIHeader .team-logos .entry.mti {
  padding-top: 10px;
  padding-bottom: 10px;
}
.MTIHeader .team-logos .entry a .logo {
  height: 100%;
}

.MTIHeader .links {
  font-size: 1.25em;
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
.MTIHeader .links a.link {
  color: #ccc;
  margin-left: 10px;
  margin-right: 10px;
}
.MTIHeader .subheader {
  background: #1B1C22;
  padding-top: .25em;
  padding-bottom: .25em;
  text-align: right;
  display: none;
}

@media (max-width: 1300px) {
  .MTIHeader .main-header .links {
    display: none;
  }
  .MTIHeader .subheader {
    display: block;
  }
}
@media (max-width: 925px) {
  .MTIHeader .main-header .app-title .title {
    font-size: 1.5em;
  }
}
@media (max-width: 700px) {
  .MTIHeader .main-header .app-title .title {
    font-size: 1.25em;
  }
  .MTIHeader .team-logos .entry {
    height: 75%;
    text-align: center;
  }
}
@media (max-width: 650px) {
  .MTIHeader .main-header .app-title .title {
    font-size: 1.1em;
  }
}
@media (max-width: 500px) {
  .MTIHeader .main-header .app-title .umd {
    font-size: .9em;
  }
  .MTIHeader .main-header .app-title .title {
    font-size: .9em;
  }
}